import Router from "next/router";
import { DependencyList, useEffect } from "react";

export const page = (href: string) => ({ href });

export const useRouterChange = (callback: () => void, deps: DependencyList = []) => {
  useEffect(() => {
    Router.events.on("routeChangeStart", callback);
    return () => Router.events.off("routeChangeStart", callback);
  }, deps);
};

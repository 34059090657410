import React, { useEffect } from "react";
import { useAuthed } from "modules/auth";
import Router from "next/router";
import pages from "pages/_router";

const IndexPage = () => {
  const authed = useAuthed();

  useEffect(() => {
    if (authed) {
      Router.replace(pages.dashboard.href);
    } else {
      Router.replace(pages.signup.href);
    }
  }, []);

  return <div>{/*<h1>Index Page</h1>*/}</div>;
};

export default IndexPage;

import { page } from "shared/lib";

const pages = {
  index: page("/"),
  login: page("/login"),
  signup: page("/signup"),
  logout: page("/logout"),
  dashboard: page("/dashboard"),
  introduce: {
    yourself: page("/introduce/yourself"),
    company: page("/introduce/company"),
  },
  profile: {
    ...page("/profile"),
    settings: "/profile/settings",
  },
  company: (company: string) => ({
    ...page(`/${company}`),

    finances: {
      ...page(`/${company}/finances`),

      education: {
        ...page(`/${company}/finances/education`),
        tableOfContent: page(`/${company}/finances/education/table-of-content`),
        step: (step: string | number) =>
          page(`/${company}/finances/education/${(step.toString().length === 1 ? "0" : "") + step.toString()}`),
      },

      yearMonth: (year: number | string, month: number | string) => ({
        ...page(`/${company}/finances/${year}-${month}`),
        plan: page(`/${company}/finances/${year}-${month}/plan`),
        fact: page(`/${company}/finances/${year}-${month}/fact`),
        results: {
          ...page(`/${company}/finances/${year}-${month}/results`),
          id: (id: string) => ({
            ...page(`/${company}/finances/${year}-${month}/results/${id}`),
            fundingRequests: page(`/${company}/finances/${year}-${month}/results/${id}/funding-requests`),
            loans: page(`/${company}/finances/${year}-${month}/results/${id}/loans`),
            funds: page(`/${company}/finances/${year}-${month}/results/${id}/funds`),
            directive: page(`/${company}/finances/${year}-${month}/results/${id}/directive`),
          }),
        },
      }),
      plan: page(`/${company}/finances/plan`),
      funds: {
        ...page(`/${company}/finances/funds`),

        fundId: (fundId: string) => ({
          ...page(`/${company}/finances/funds/${fundId}`),
          expenseItems: {
            ...page(`/${company}/finances/funds/${fundId}/expense-items`),
            edit: page(`/${company}/finances/funds/${fundId}/expense-items/edit`),
          },
          history: page(`/${company}/finances/funds/${fundId}/history`),
          fundingRequests: page(`/${company}/finances/funds/${fundId}/funding-requests`),
          settings: page(`/${company}/finances/funds/${fundId}/settings`),
        }),
      },
      fundingRequests: {
        ...page(`/${company}/finances/funding-requests`),
        new: page(`/${company}/finances/funding-requests/new`),
        id: (id: string) => ({
          ...page(`/${company}/finances/funding-requests/${id}`),
          edit: page(`/${company}/finances/funding-requests/${id}/edit`),
        }),
      },
      payouts: {
        ...page(`/${company}/finances/payouts`),
        history: page(`/${company}/finances/payouts/history`),
      },
      deferredIncomes: {
        ...page(`/${company}/finances/deferred-incomes`),
        id: (id: string) => page(`/${company}/finances/deferred-incomes/${id}`),
      },
      loans: page(`/${company}/finances/loans`),
      settings: page(`/${company}/finances/settings`),
      reports: {
        balance: page(`/${company}/finances/reports/balance`),
        cashFlow: page(`/${company}/finances/reports/cash-flow`),
        expenses: page(`/${company}/finances/reports/expenses`),
        loans: page(`/${company}/finances/reports/loans`),
        activeFundingRequests: page(`/${company}/finances/reports/active-funding-requests`),
      },
    },
    departments: page(`/${company}/departments`),
    settings: {
      ...page(`/${company}/settings`),
      users: page(`/${company}/settings/users`),
      roles: page(`/${company}/settings/roles`),
      general: page(`/${company}/settings/general`),
    },
  }),

  admin: {
    ...page("/admin"),

    company: (companySlug: string) => ({
      ...page(`/admin/${companySlug}`),
      profile: page(`/admin/${companySlug}/profile`),
      courses: page(`/admin/${companySlug}/courses`),
      mentors: page(`/admin/${companySlug}/mentors`),
    }),

    invites: {
      ...page("/admin/invites"),
      id: (id: string) => page(`/admin/invites/${id}`),
    },

    settings: {
      ...page("/admin/settings"),
      members: page("/admin/settings/members"),
      courses: page("/admin/settings/courses"),
      mailings: page("/admin/settings/mailings"),
    },
  },
};

export default pages;
